<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form inline>
        <el-form-item>
          <el-input v-model="searchForm.nickname" placeholder="输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.phone" placeholder="输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="用户编号">
          <el-input-number v-model="searchForm.id"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.level">
            <el-option :value="0" label="全部用户"></el-option>
            <el-option v-for="(item,key) in PartnershipUserLevel" :key="key" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="flex-def flex-cCenter">
            <el-button @click="searchGo" type="primary">搜索</el-button>
            <el-button @click="searchReset">重置</el-button>
            <div style="margin-left: 15px">
              <Y_choose_member @change="chooseShopowner">
                <el-button>手动添加店长</el-button>
              </Y_choose_member>
            </div>
            <div style="margin-left: 15px">
              <Y_choose_member @change="choosePartner">
                <el-button>手动添加合伙人</el-button>
              </Y_choose_member>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <el-table :data="list">
        <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
        <el-table-column label="人员信息" show-overflow-tooltip>
          <template #default="s">
            <div class="flex-def">
              <img :src="s.row.info.avatar | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem" alt="">
              <div style="margin-left: 15px">
                <div>{{s.row.info.nickname}}({{s.row.info.id}})</div>
                <div>{{s.row.info.phone}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="级别" show-overflow-tooltip>
          <template #default="s">
            <el-tag :type="s.row.level === PartnershipUserLevel.shopowner.value ? 'success' :'warning'" effect="dark">
              {{EnumValue2Label(s.row.level,PartnershipUserLevel)}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="加入日期时间" show-overflow-tooltip>
          <template #default="s">
            <div class="y-desc">普通用户：{{s.row.created_at | date}}</div>
            <div class="y-desc" v-if="s.row.level > PartnershipUserLevel.normal.value">店长：{{s.row.shopowner_time | date}}</div>
            <div class="y-desc" v-if="s.row.level > PartnershipUserLevel.shopowner.value">合伙人：{{s.row.partner_time | date}}</div>
          </template>
        </el-table-column>
        <el-table-column label="直推人" show-overflow-tooltip>
          <template #default="s">
            <div class="flex-def" v-if="s.row.direct_u.id">
              <img :src="s.row.direct_u.avatar | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem" alt="">
              <div style="margin-left: 15px">
                <div>{{s.row.direct_u.nickname}}({{s.row.direct_u.id}})</div>
                <div>{{s.row.direct_u.phone}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="间推人" show-overflow-tooltip>
          <template #default="s">
            <div class="flex-def" v-if="s.row.indirect_u.id">
              <img :src="s.row.indirect_u.avatar | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem" alt="">
              <div style="margin-left: 15px">
                <div>{{s.row.indirect_u.nickname}}({{s.row.indirect_u.id}})</div>
                <div>{{s.row.indirect_u.phone}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="升级原因" show-overflow-tooltip>
          <template #default="s">
            <div class="y-desc" v-if="s.row.level > PartnershipUserLevel.normal.value">
              店长：
              <span v-if="s.row.shopowner_order_id && s.row.shopowner_goods_id">订单编号[{{s.row.shopowner_order_id}}] 商品编号[{{s.row.shopowner_goods_id}}]</span>
              <span v-else>手动添加</span>
            </div>
            <div class="y-desc" v-if="s.row.level > PartnershipUserLevel.shopowner.value">
              合伙人：
              <span v-if="s.row.partner_order_id && s.row.partner_goods_id">订单编号[{{s.row.partner_order_id}}] 商品编号[{{s.row.partner_goods_id}}]</span>
              <span v-else>手动添加</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="分红明细" show-overflow-tooltip>
          <template #default="s">
            <user-award-log :uid="s.row.uid">
              <el-tag type="success" effect="dark">查看明细</el-tag>
            </user-award-log>
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template #default="s">
            <el-button size="mini" v-if="s.row.level > PartnershipUserLevel.normal.value" @click="levelNormal(s.row.uid)" type="danger">清空等级</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>

import {PartnershipUserLevel} from "@/common/mod/plugin/partnership/userProfile";
import Y_choose_member from "@/components/shop/y_choose_member.vue";
import {EnumValue2Label} from "@/common/util";
import UserAwardLog from "@/components/shop/plugin/partnership/UserAwardLog.vue";

const searchFormTpl = {
  phone:"",
  id:0,
  level:0,
  nickname:"",
}

export default {
  computed: {
    PartnershipUserLevel() {
      return PartnershipUserLevel
    }
  },
  components: {UserAwardLog, Y_choose_member},
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      searchForm:{...searchFormTpl}
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    levelNormal(id){
      this.$u.api.shop.plugin.partnership.userLevelNormal({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    EnumValue2Label,
    handsLevel(id,level){
      this.$u.api.shop.plugin.partnership.userProfileHands({
        uid:id,
        level:level,
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    chooseShopowner(e){
      this.handsLevel(e.id,PartnershipUserLevel.shopowner.value)
    },
    choosePartner(e){
      this.handsLevel(e.id,PartnershipUserLevel.partner.value)
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    searchGo(){
      this.page = 1;
      this.load();
    },
    searchReset(){
      this.page = 1;
      this.searchForm = {...searchFormTpl};
      this.load();
    },
    load() {
      this.$u.api.shop.plugin.partnership.userProfileSearch({
        page: this.page,
        ...this.searchForm,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>