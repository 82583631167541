<template>
  <div>
    <div @click="open">
      <slot></slot>
    </div>
    <el-dialog title="分红明细" :visible.sync="show" destroy-on-close>
      <div class="flex-def flex-cCenter" style="width: 100%;padding: .5rem">
        <el-date-picker v-model="range"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <el-button-group>
          <el-button @click="search">搜索</el-button>
          <el-button @click="searchReset">重置</el-button>
        </el-button-group>
      </div>
      <el-table :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column label="分红来源项目">
          <template #default="s">
            <div>订单编号：{{s.row.order_id}}</div>
            <div>商品编号：{{s.row.goods_id}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="fee" label="分红金额"></el-table-column>
        <el-table-column label="分红来源用户">
          <template #default="s">
            {{s.row.source_user.nickname}}({{s.row.source_user.id}})
          </template>
        </el-table-column>
        <el-table-column prop="log" label="分红日期时间">
          <template v-slot="s">
            {{ s.row.created_at | date }}
          </template>
        </el-table-column>
        <el-table-column prop="log" label="记录"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page"
                         @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "UserAwardLog",
  props: {
    uid: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      show: false,
      range: [],
      list: [],
      page: 1,
      total: 0,
    }
  },
  methods: {
    searchReset(){
      this.range = [];
      this.search();
    },
    search(){
      this.award_log_list = [];
      this.award_log_total = 0;
      this.pageChange(1);
    },
    pageChange(e) {
      this.page = e;
      this.open();
    },
    open() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.shop.plugin.partnership.userAwardLog({
        page: this.page,
        id: this.uid,
        range: this.range,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
        this.show = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    }
  }
}
</script>

<style scoped>

</style>