export const PartnershipUserLevel = {
    normal:{
        label:"普通用户",
        value:1
    },
    shopowner:{
        label:"店长",
        value:2
    },
    partner:{
        label:"合伙人",
        value:3
    },
}
